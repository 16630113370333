import React from 'react';
import styled from '@emotion/styled';
import { graphql } from 'gatsby';
import { RichText } from 'prismic-reactjs';

import SEO from 'components/SEO';
import { BREAKPOINTS, MOBILE_SIDE_PADDING } from 'utils/constants';

export const query = graphql`
  query LegalQuery($id: String) {
    prismicLegalPage(id: { eq: $id }) {
      dataRaw
    }
  }
`;

const Container = styled.div((props) => ({
  padding: '206px min(300px, 18%)',
  [BREAKPOINTS[800]]: {
    padding: `206px ${MOBILE_SIDE_PADDING}`,
  },
  ['a']: {
    fontWeight: 'bold',
    color: props.theme.colors.white,
  },
}));

const TitleContainer = styled.div((props) => ({
  marginBottom: 80,
  color: props.theme.colors.white,
}));

const ContentContainer = styled.div((props) => ({
  fontSize: 16,
  lineHeight: '28px',
  letterSpacing: 1,
  fontWeight: 400,
  opacity: 0.8,
  color: props.theme.colors.white,
}));

const LegalPage = ({ data }) => {
  const pageData = data.prismicLegalPage.dataRaw;

  return (
    <div>
      <SEO
        pageTitle={pageData.page_title && RichText.asText(pageData.page_title)}
        ogTitle={pageData.og_title && RichText.asText(pageData.og_title)}
        twitterTitle={pageData.twitter_title && RichText.asText(pageData.twitter_title)}
        pageDescription={pageData.page_description && RichText.asText(pageData.page_description)}
        ogDescription={pageData.og_description && RichText.asText(pageData.og_description)}
        twitterDescription={
          pageData.twitter_description && RichText.asText(pageData.twitter_description)
        }
        pageImage={pageData?.page_image?.url}
        pageImageAlt={pageData?.page_image?.alt}
        twitterImage={pageData?.twitter_image?.url}
        twitterImageAlt={pageData?.twitter_image?.alt}
        ogImage={pageData?.og_image?.url}
        ogImageAlt={pageData?.og_image?.url?.alt}
      />
      <Container>
        <TitleContainer>
          <RichText render={pageData.title} />
        </TitleContainer>
        <ContentContainer>
          <RichText render={pageData.content} />
        </ContentContainer>
      </Container>
    </div>
  );
};

export default LegalPage;
